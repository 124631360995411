import React, { useCallback, useEffect, useRef, useState } from 'react'

import { scoringRequest, ScoringRequest } from '@a10yll/common/yup/index.js'
import { getFromLocalStorage, logger, setToLocalStorage } from '@a10base/frontend/util/index.js'
import { scoringOptions } from '@a10yll/common/index.js'
import { trpc } from '../../../../util/trpc.js'
import { useThrottledAsyncFn } from '@a10base/frontend/hooks/index.js'
import { ScoringForm } from './ScoringForm.js'
import { nop } from '@a10base/common/misc.js'
import { ContactFormModal } from './ContactFormModal.js'

const lsRequestKey = 'scoring-request'
const thisYear = new Date().getFullYear()

const emptyScoringRequest: ScoringRequest = {
    business_id: '',
    loan_amount: -1,
    collateral: false,
    interest: scoringOptions.interest[0].value,
    payback_time: scoringOptions.paybackTime[2].value,
}

export function YrityslainalaskuriPage() {
    const initialLoadDone = useRef<boolean>(false)
    const [modal, setModal] = useState<'contact-form' | undefined>(undefined)
    const [request, setRequest] = useState<ScoringRequest>(() => {
        const savedRequest = getFromLocalStorage<ScoringRequest>(lsRequestKey)
        if (savedRequest) {
            try {
                const request: ScoringRequest = { ...savedRequest, business_id: '' }
                return request
            } catch (error) {
                logger.error('Failed to load saved input', error)
            }
        }
        return { ...emptyScoringRequest }
    })
    const [scoring, setScoring] = useState<{ score: number; scoringId: number } | undefined>(
        undefined
    )

    const loadScore = useCallback(
        async (request: ScoringRequest) => {
            try {
                const response = await trpc.public.calculateScore.mutate(request)
                if (response) {
                    setScoring(response)
                }
            } catch (error) {
                logger.warn('Failed to load score', error)
                setScoring(undefined)
            }
        },
        [setScoring]
    )

    const loadScoreThrottled = useThrottledAsyncFn<ScoringRequest>(loadScore, 500)

    const updateRequest = useCallback(
        (updatedRequest: ScoringRequest) => {
            if (updatedRequest.business_id) {
                setRequest(updatedRequest)
                setToLocalStorage(lsRequestKey, updatedRequest)
                const isValid = scoringRequest.isValidSync(updatedRequest)
                if (isValid) {
                    loadScoreThrottled.setParams(updatedRequest)
                }
            }
        },
        [loadScoreThrottled]
    )

    useEffect(() => {
        if (!initialLoadDone.current) {
            const isValid = scoringRequest.isValidSync(request)
            if (isValid) {
                loadScoreThrottled.setParams(request)
            }
            initialLoadDone.current = true
        }
    }, [request, loadScoreThrottled])

    const onOpenApplicationForm = useCallback(() => {
        if (scoring?.scoringId) {
            trpc.public.contactFormOpened.mutate({ id: scoring.scoringId }).catch(nop)
        }
        setModal('contact-form')
    }, [scoring?.scoringId])

    return (
        <div className="yll">
            <Header />

            <div className="scoring-form-block" id="scoring-form">
                <div className="scoring-form-wrapper">
                    <h2>
                        Arvioi yrityksesi mahdollisuudet saada yrityslaina tai yritysluotto Sinun
                        ehdoilllasi
                    </h2>
                    <ScoringForm
                        input={request}
                        score={scoring?.score}
                        onChange={updateRequest}
                        onOpenApplicationForm={onOpenApplicationForm}
                    />
                </div>
            </div>

            <MitenToimii />

            <KäytettävätTiedot />

            <YritysrahoitusJaSenEriMuodot />

            <MitäEroa />

            <MitenPäätöksentekoToimii />

            <Footer />

            {modal === 'contact-form' && scoring && request.business_id && (
                <ContactFormModal
                    businessId={request.business_id}
                    scoringId={scoring.scoringId}
                    onClose={() => setModal(undefined)}
                />
            )}
        </div>
    )
}

const Header = React.memo(function Header() {
    return (
        <>
            <div className="header">
                <h1>
                    yrityslaina<span>laskuri</span>.fi
                </h1>
                <a className="how-it-works" href="#how-it-works">
                    <h3>Miten yrityslainalaskuri toimii?</h3>
                </a>
                <div className="logo">
                    <a href="https://www.capitalbox.fi/" target="_blank">
                        <div>Palvelun&nbsp;tarjoaa</div>
                        <img src="/public/logo2.png" />
                    </a>
                </div>
            </div>
            {/* Blue box with logo */}
            <div className="blue-box-block">
                <div className="blue-box" />
            </div>
        </>
    )
})

const MitenToimii = React.memo(function MitenToimii() {
    return (
        <div className="content-block" id="how-it-works">
            <div className="panel left">
                <h1>Miten yrityslainalaskuri toimii?</h1>
                <div className="info-left">
                    <strong>Huom:</strong> Yrityslainalaskurin tarjoamat suuntaa-antavat arviot
                    eivät ole sitovia lainalupauksia.
                </div>
            </div>
            <div className="panel right">
                <p>
                    Yrityslainalaskurin avulla yritys voi arvioida mahdollisuuksiaan saada
                    rahoitusta ennen varsinaisen yrityslainahakemuksen jättämistä pankille tai
                    rahoituslaitokselle. Palvelun keskeinen tavoite on tarjota yrittäjälle ajan
                    säästöä sekä yritysrahoitustietoa.
                </p>
                <p>
                    Monella yrityksellä on varsin hyvät mahdollisuudet saada rahoitusta, mutta
                    yrityslainan tai yritysluoton hakuprosessi voi olla raskas, erityisesti silloin,
                    jos yrityslainaa tai -luottoa ei myönnetä odotusten mukaisesti.
                </p>
                <p>
                    Yrityslainalaskurissa sovelletaan yrityspankkimaailmaan perustuvia
                    arviointimenetelmiä kevennetysti.
                </p>
                <div className="info-right">
                    <p>
                        <strong>Huom:</strong> Yrityslainalaskurin tarjoamat suuntaa-antavat arviot
                        eivät ole sitovia lainalupauksia.
                    </p>
                </div>
            </div>
        </div>
    )
})

const KäytettävätTiedot = React.memo(function KäytettävätTiedot() {
    return (
        <div className="content-block bg-gray">
            <div className="panel left">
                <h1>Yrityslainalaskurissa käytettävät tiedot</h1>
            </div>
            <div className="panel right">
                <h2>Luottoluokitus Rating Alfa</h2>
                <p>
                    <a href="https://asiakastieto.fi">Suomen Asiakastieto Oy:n</a> tarjoama Rating
                    Alfa on yrityksen taloudellista vakautta, luottokelpoisuutta ja maksukykyä
                    kuvaava luottoluokitus. Se on Suomen Asiakastiedon rekisteröimä tuotemerkki,
                    joka perustuu monipuoliseen analyysiin yrityksen taloudellisesta informaatiosta,
                    kuten tilinpäätöstiedoista, maksutapahtumista ja mahdollisista maksuhäiriöistä.
                    Rating Alfa on yksi yleisempiä ja tunnetuimpia luottoluokitusmalleja Suomessa.
                </p>
                <p>
                    Yrityspankit ja rahoituslaitokset saattavat käyttää Rating Alfaa sellaisenaan
                    tai sovelletusti osana automaattista tai manuaalista yritysrahoituksen
                    päätöksentekoprosessia.
                </p>
                <p>
                    Yrityslainalaskurissa voi valita yrityksen Rating Alfa -luottoluokituksen
                    anonyymisti, mikäli tämä on tiedossa. Valinnan voi myös jättää tyhjäksi, jolloin
                    kyseistä tietoa ei käytetä osana arviointia. Oletuksena on tällöin, että
                    yrityksen luottokelpoisuus on hyvä (ei positiivista eikä negatiivista
                    vaikutusta).
                </p>
                <h2>Yrityksen liikevaihtoluokka ja rahoitustarve</h2>
                <p>
                    Yrityksen liikevaihto on tärkeä tieto pankeille ja rahoituslaitoksille etenkin
                    arvioitaessa haettavaa rahoitusmäärää. Rahoituksen määrä ei voi olla liian suuri
                    suhteessa yrityksen vuosittaiseen liikevaihtoon.
                </p>
                <p>
                    Yleisesti ottaen pankit ja rahoituslaitokset voivat harkita lainaavansa pienille
                    ja keskisuurille yrityksille summan, joka on 10-50 % yrityksen vuosittaisesta
                    liikevaihdosta. Tämä on kuitenkin vain karkea arvio, ja todellinen lainan määrä
                    voi olla suurempi tai pienempi riippuen yrityksen tilinpäätöstiedoista,
                    luottoluokituksesta ja maksukäyttäytymisestä.
                </p>
                <p>
                    Erityisesti vahvan vakuuden tai erinomaisen luottokelpoisuuden yrityksille
                    myönnettävän lainan määrä voi ylittää 50 % liikevaihdosta.
                </p>

                <h2>Vakuudellinen tai vakuudeton yrityslaina</h2>
                <p>
                    <a href="https://fi.wikipedia.org/wiki/Laina#Vakuus">Vakuudet</a> ovat
                    lainanottajan pankille tai rahoituslaitokselle tarjoama turva lainan maksamatta
                    jättämisen varalta. Rahoituslaitos arvioi vakuuksien arvon ja niiden
                    realisoitavuuden, eli kuinka nopeasti ja millä hinnalla vakuuden voisi muuttaa
                    rahaksi. Vakuudet voivat olla esimerkiksi kiinteistöjä tai maa-alueita.
                </p>
                <p>
                    Mikäli yritys antaa vakuuksia osana yrityslainaneuvotteluja, on myös
                    yrityslainan korko- ja kulutaso matalampi. Vaihtoehtoisesti yritys voi hakea
                    vakuudetonta yrityslainaa tai -luottoa, jolloin kyseeseen tulee ainoastaan
                    henkilötakaus, sekä korkeampi korko- ja kulutaso.
                </p>

                <h2>Korko- ja kulutaso sekä lainan takaisinmaksuaika</h2>
                <p>
                    Yritysrahoituksen korot ja kulut ilmoitetaan eri tavoin pankeissa ja
                    rahoituslaitoksissa. Tyypillisesti puhutaan kuitenkin vuosittaisesta korko- ja
                    kulutasosta, eli yrityslainan todellisesta vuosikorosta kuluineen.
                </p>
                <p>
                    Lyhyempi lainan takaisinmaksuaika on aina edullisempi vaihtoehto yritykselle.
                    Kääntöpuolena on kuitenkin isompi kuukausittainen lainanhoitorasite, jolloin
                    kassavirtoja ei välttämättä pysty hyödyntämään yhtä tehokkaasti investointeihin
                    ja kasvuun. Luotettavat ja asiantuntevat rahoituslaitokset auttavat yrityksiä
                    tämän kokonaisuuden arvioimisessa.
                </p>
            </div>
        </div>
    )
})

const YritysrahoitusJaSenEriMuodot = React.memo(function YritysrahoitusJaSenEriMuodot() {
    return (
        <div className="content-block">
            <div className="panel left">
                <h1>Mitä on yritysrahoitus ja sen eri muodot?</h1>
            </div>
            <div className="panel right">
                <h2>Luottoluokitus Rating Alfa</h2>
                <p>
                    <a href="https://fi.wikipedia.org/wiki/Yritysrahoitus">Yritysrahoitus</a> on
                    keskeinen elementti jokaisen yrityksen toiminnassa, jotta sillä on toimintaansa
                    varten rahaa investointeihin, operatiiviseen toimintaan ja kasvun
                    mahdollistamiseen. Rahoitus voidaan jakaa karkeasti kahteen pääluokkaan: oma
                    pääoma ja velkarahoitus. Oma pääoma sisältää esimerkiksi yrittäjän investoimat
                    varat, kun taas velkarahoitus koostuu ulkopuolisista lainoista ja luotoista,
                    jotka yrityksen tulee maksaa takaisin korkoineen.
                </p>
                <p>
                    Erityisen huomionarvoinen osa-alue yritysrahoituksessa ovat yrityslainat, jotka
                    voidaan jaotella vakuudellisiin ja vakuudettomiin lainoihin. Vakuudelliset
                    lainat vaativat lainanottajalta jonkinlaista reaalivakuutta, kuten kiinteistöjä
                    tai maa-alueita, jotka toimivat lainan takaisinmaksun turvana. Vakuudettomissa
                    lainoissa puolestaan ei vaadita erillisiä vakuuksia, mutta niiden korot ovat
                    tyypillisesti korkeammat vakuudellisiin lainoihin verrattuna{' '}
                </p>
                <p>
                    Pankkien ja rahoituslaitosten päätöksenteko yrityslainojen myöntämisessä
                    perustuu monitahoiseen analyysiin. Tarkastelun kohteena ovat mm. yrityksen
                    taloudellinen historia, maksukyky ja rahoituksen käyttötarkoitus. Lisäksi
                    arvioidaan toimialan riskejä ja yrityksen asemaa markkinoilla.
                </p>
            </div>
        </div>
    )
})

const MitäEroa = React.memo(function MitäEroa() {
    return (
        <>
            <div className="content-block bg-blue">
                <div className="panel blue">
                    <h1 className="white">Mitä eroa on yrityslainalla ja yritysluotolla?</h1>
                    <p className="white">
                        Yrityslaina on virallinen sopimus pankin tai muun rahoittajan kanssa, jossa
                        sovitaan lainan määrästä, takaisinmaksuaikataulusta ja koroista etukäteen.
                        Laina maksetaan yritykselle yleensä kerralla ja se on tarkoitettu suurempiin
                        investointeihin, kuten uuden koneen hankintaan tai vaikkapa
                        laajennusremonttiin.
                    </p>
                    <p className="white">
                        Yritysluotto on taas joustavampi vaihtoehto, joka toimii kuin luottokortti.
                        Voit käyttää luottoa tarpeesi mukaan, maksat korkoa vain nostetusta summasta
                        ja voit tehdä uusia nostoja luottorajan puitteissa. Tämä on kätevää
                        esimerkiksi silloin, kun tarvitset rahaa nopeasti yllättäviin menoihin tai
                        kassavirran tasapainottamiseen.
                    </p>
                </div>
            </div>
            {/* Three boxes */}
            <div className="three-boxes-block">
                <div className="box-1"></div>
                <div className="box-2"></div>
                <div className="box-3"></div>
            </div>
        </>
    )
})

const MitenPäätöksentekoToimii = React.memo(function MitenPäätöksentekoToimii() {
    return (
        <div className="content-block bg-gray">
            <div className="panel left">
                <h1>
                    Miten pankkien ja rahoituslaitosten päätöksenteko toimii yrityslainaa
                    myönnettäessä?
                </h1>
            </div>
            <div className="panel right">
                <p>
                    Pankkien ja rahoituslaitosten päätöksenteossa yrityslainan myöntämiseen
                    vaikuttavat monet tekijät. Lähdetään purkamaan näitä perusteita yksi kerrallaan.
                </p>

                <h2>Liiketoiminnan historia ja taloudellinen tilanne</h2>
                <p>
                    Pankki tai rahoituslaitos arvioi ensin yrityksen taloudellista historiaa ja
                    nykytilaa. Tähän sisältyy tilinpäätöstiedot, maksukäyttäytyminen ja
                    velkaantumisaste.
                </p>

                <h2>Maksukyvyn arviointi</h2>
                <p>
                    Maksukyvyn arvioinnissa keskitytään kassavirtalaskelmiin, budjetteihin ja
                    tulosennusteisiin. Harvemmin tosin pankit tai rahoituslaitokset pyytävät näitä
                    pienemmissä rahoitustarpeissa, ellei kyse ole uuden yrityksen rahoittamisesta
                    esimerkiksi <a href="https://www.finnvera.fi/">Finnveran</a> avulla.
                </p>

                <p>
                    Käytännössä pankki haluaa varmistua, että yrityksellä on riittävästi varoja
                    lainan lyhentämiseen ja korkojen maksuun tulevaisuudessa. Tämä on kuin
                    tarkastaisi, että yrityksen talous ei kaadu, jos tuuli kääntyy.
                </p>

                <p>
                    Edistyneet pankit ja rahoituslaitokset selvittävät yrityksen lähtökohtaisen
                    luottokelpoisuuden Suomessa nopeasti esimerkiksi Suomen Asiakastieto Oy:n
                    tarjoamien tietolähteiden avulla osana yrityslainahakemuksen vastaanottamista.{' '}
                    <a href="https://fi.wikipedia.org/wiki/Luottotieto">
                        Lisää tietoa luottotiedosta
                    </a>
                </p>

                <h2>Mahdolliset reaalivakuudet</h2>
                <p>
                    Kuten aiemmin mainittu, vakuudet ovat lainanottajan pankille tai
                    rahoituslaitokselle tarjoama turva. Vakuudet voivat olla esimerkiksi
                    kiinteistöjä, koneita, tai maa-alueita.
                </p>
                <p>
                    <a href="https://www.prh.fi/fi/yrityskiinnitykset/yrityskiinnitys.html">
                        Yrityskiinnitys
                    </a>{' '}
                    on myös eräänlainen vakuus pankille tai rahoituslaitokselle. Jos yritys antaa
                    pankille yrtyskiinnityksen, on pankilla etuoikeus saada omansa pois pahan päivän
                    sattuessa. Yrityksen myyntisaatavat voivat myös kuulua yrityskiinnityksen
                    piiriin.
                </p>
                <p>
                    Yrityskiinnitykset saattavat laajan ja rajoittamattoman luonteensa puolesta myös
                    rajoittaa yrityksen rahoitusneuvottelumahdollisuuksia uuden rahoittajaosapuolen
                    kanssa.
                </p>

                <h2>Lainan käyttötarkoitus</h2>
                <p>
                    Rahoituslaitos tarkastelee yrityksen liiketoimintasuunnitelmaa ja sitä, miten
                    yritys aikoo käyttää lainan. Onko suunnitelma realistinen ja perusteltu?
                    Rahoituslaitos haluaa nähdä, että laina investoidaan toimintaan, joka edistää
                    yrityksen kasvua ja kannattavuutta.
                </p>
                <p>
                    Tärkeintä on kuitenkin se, että yrittäjä tai taloudesta vastaava henkilö on
                    totuudenmukainen lainan käyttötarkoituksessa. Toisinaan esimerkiksi ns.
                    “siltarahoitus” jonkin ison investoinnin järjestämiseksi on täysin perusteltu
                    syy hakea yrityslainaa.
                </p>

                <h2>Toimialan analyysi</h2>
                <p>
                    Pankit ja rahoituslaitokset analysoivat myös toimialan tilaa ja näkymiä. Ne
                    katsovat, minkälaisessa markkinatilanteessa yritys operoi ja miten toimialan
                    riskit vaikuttavat yrityksen kykyyn selviytyä taloudellisista sitoumuksistaan.
                    Toimiala voi vaikuttaa lainan korkoihin sekä kuluihin.
                </p>

                <h2>Päätöksentekoprosessi</h2>
                <p>
                    Kun yllä mainitut tiedot on kerätty, siirtyy lainahakemus pankin tai
                    rahoituslaitoksen sisäiseen käsittelyyn, jossa riskienhallinta ja
                    luotonanto-osasto arvioivat hakemuksen kokonaisuutena.
                </p>
                <p>
                    Jos kaikki palaset ovat kohdillaan, pankki voi päättää myöntää lainan. Jos
                    epävarmuustekijöitä on liikaa, hakemus hylätään tai pyydetään lisätietoja.
                </p>

                <h2>Lopputulema</h2>
                <p>
                    Prosessi on monivaiheinen ja voi muistuttaa enemmän maratonjuoksua kuin
                    pikajuoksua. Nykyisin kuitenkin etevät rahoituslaitokset hoitavat valtaosan
                    yritysanalyysista sekunneissa hyödyntäen julkisia yritystietoja.
                </p>
                <p>
                    Yrittäjän on tärkeä valmistautua tarjoamaan pankille tai rahoituslaitokselle
                    kaikki tarvittava tieto päätöksenteon tueksi. Luotettavissa pankeissa ja
                    rahoituslaitoksissa pääset keskustelemaan henkilökohtaisesti
                    rahoitusneuvottelijan kanssa ainakin puhelimessa, kun yrityslainahakemuksesi
                    etenee käsittelyvaiheeseen.
                </p>
                <p>
                    Pienemmissä rahoitustarpeissa yritys voi saada yritsylainan tai -luoton jopa
                    päivän sisällä osana automaattista päätöksentekoprosessia.
                </p>

                <a className="button" href="#scoring-form">
                    Siirry yrityslainalaskuriin
                </a>
            </div>
        </div>
    )
})

const Footer = React.memo(function Footer() {
    return (
        <div className="footer">
            <h1>
                yrityslaina<span>laskuri</span>.fi
            </h1>
            <div className="terms">
                <a href="https://www.capitalbox.fi/terms-use">Palvelun käyttöehdot</a>
                <a href="https://www.capitalbox.fi/terms-use">Tietosuojakäytäntö</a>
                <a href="https://www.capitalbox.fi/evasteiden-kaytto">Evästekäytäntö</a>
            </div>
            <p className="copyright">
                © 2015-{thisYear} CapitalBox. Tämän verkkopalvelun omistaa ja tarjoaa CapitalBox
                Suomi, joka on osa Multitude Groupia.
            </p>
        </div>
    )
})
