import React, { useEffect, useRef, MutableRefObject, useState, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { runningInBrowser } from '@a10base/common/running-in-browser.js'
import { arrayShallowEquals } from '@a10base/common/misc.js'

import { logger } from '../util/client-logger.js'
import { getFromLocalStorage, setToLocalStorage } from '../util/localstorage.js'
import { subscribeToMessageBase } from '../message-bus/message-bus.js'
import { UrlChanged } from '../message-bus/messages.js'
import { Breakpoints } from '../redux/index.js'

export function useIsMountedRef(): MutableRefObject<boolean> {
    const isMountedRef = useRef(false)
    useEffect(() => {
        isMountedRef.current = true
        return () => {
            isMountedRef.current = false
        }
    }, [])
    return isMountedRef
}

export function useDelay(delayMs: number): boolean {
    const [triggered, setTriggered] = useState<boolean>(false)
    useEffect(() => {
        const timeout = setTimeout(() => {
            setTriggered(true)
        }, delayMs)
        return () => {
            clearTimeout(timeout)
        }
    }, [delayMs])
    return triggered
}

export function useInterval(updateIntervalMs: number, intervalDescription?: string): number {
    const [now, setNow] = useState<number>(new Date().getTime())

    useEffect(() => {
        const timeout = setInterval(() => {
            setNow(new Date().getTime())
            logger.debug(`Triggering interval "${intervalDescription || '?'}"`)
        }, updateIntervalMs)
        return function __clearInterval() {
            logger.debug(`Clearing interval "${intervalDescription || '?'}"`)
            clearInterval(timeout)
        }
    }, [updateIntervalMs, intervalDescription])

    return now
}

export function useIntervalFn<T>(updateIntervalMs: number, valueFn: () => T): T {
    const [value, setValue] = useState<T>(valueFn())
    const _value = useRef<T>(value)

    useEffect(() => {
        const timeout = setInterval(() => {
            const newValue = valueFn()
            if (_value.current !== newValue) {
                _value.current = newValue
                setValue(newValue)
            }
        }, updateIntervalMs)
        return function __clearInterval() {
            clearInterval(timeout)
        }
    }, [updateIntervalMs, valueFn])

    return value
}

interface UseClickOutsideOutput<T> {
    ref: MutableRefObject<T | null>
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}
export function useClickOutside<T extends HTMLElement>(
    initiallyOpen?: boolean
): UseClickOutsideOutput<T> {
    const ref = useRef<T | null>(null)
    const [open, setOpen] = useState<boolean>(!!initiallyOpen)

    const handleDocumentClick = useCallback((event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            setOpen(false)
        }
    }, [])

    useEffect(() => {
        if (runningInBrowser) {
            document.addEventListener('click', handleDocumentClick, { capture: true })
            return () => {
                document.removeEventListener('click', handleDocumentClick, { capture: true })
            }
        }
        return
    }, [handleDocumentClick])

    return { ref, open, setOpen }
}

export function useRandomIds(count: number, prefix?: string): string[] {
    return useMemo(() => {
        const ids: string[] = []
        for (let i = 0; i < count; i += 1) {
            ids.push(`${prefix || 'id'}-${i}-${Math.round(Math.random() * 1000000)}`)
        }
        return ids
    }, [count, prefix])
}

export function useRandomId(prefix?: string): string {
    return useMemo(() => {
        return `${prefix || 'id'}-${Math.round(Math.random() * 1000000)}`
    }, [prefix])
}

export function useStateLS<T>(
    lsKey: string,
    defaultState: T
): [T, React.Dispatch<React.SetStateAction<T>>] {
    const [value, setValue] = useState<T>(() => getFromLocalStorage<T>(lsKey) ?? defaultState)
    const currentValue = useRef<T>(value)
    const setValueAndLS = useCallback(
        (newValue: T | ((prev: T) => T)) => {
            setValue(newValue)
            currentValue.current =
                newValue instanceof Function ? newValue(currentValue.current) : newValue
            setToLocalStorage(lsKey, currentValue.current)
        },
        [lsKey]
    )
    return [value, setValueAndLS]
}

export function useSearchParamChanges(
    paramsToWatch: string[],
    onChange: (param: string) => void
): void {
    const onChangeRef = useRef(onChange)
    onChangeRef.current = onChange
    useEffect(() => {
        return subscribeToMessageBase('url-changed', (message: UrlChanged) => {
            for (const param of paramsToWatch) {
                const prev = message.prevSearchParams.array[param]
                const current = message.searchParams.array[param]
                if (!arrayShallowEquals(prev, current)) {
                    onChangeRef.current(param)
                    break
                }
            }
        })
    }, paramsToWatch)
}

export function useUrlPathChanged(onChange: () => void): void {
    const onChangeRef = useRef(onChange)
    onChangeRef.current = onChange
    useEffect(() => {
        return subscribeToMessageBase('url-changed', (message: UrlChanged) => {
            if (message.prevPathname !== message.pathname) {
                onChangeRef.current()
            }
        })
    }, [])
}

// export function useStateUrl(
//     searchParams: SearchParams,
//     paramName: string,
//     onChange?: (value: string | undefined) => void // useCallback
// ): [string | undefined, (value: string | undefined, replace?: boolean) => void] {
//     const onChangeRef = useRef(onChange)
//     onChangeRef.current = onChange
//     const value = searchParams.first[paramName]
//     const setValue = useCallback(
//         (newValue: string | undefined, replace?: boolean) => {
//             updateUrlQuery({ [paramName]: newValue }, { replace })
//             onChangeRef.current?.(newValue)
//         },
//         [paramName]
//     )
//     return [value, setValue]
// }

// export function useStateUrlArray<T extends string>(
//     paramName: string,
//     onChange?: (value: T[] | undefined) => void // useCallback
// ): [T[], (value: T[] | undefined, replace?: boolean) => void] {
//     const onChangeRef = useRef(onChange)
//     onChangeRef.current = onChange
//     // eslint-disable-next-line @typescript-eslint/no-explicit-any
//     const value = useSelector<any>(state => state.url.searchParams.array[paramName]) as T[]
//     const setValue = useCallback(
//         (newValue: T[] | undefined, replace?: boolean) => {
//             updateUrlQuery({ [paramName]: newValue }, { replace })
//             onChangeRef.current?.(newValue)
//         },
//         [paramName]
//     )
//     return [value, setValue]
// }

export function useStateEphemeral<T>(
    ttlMs: number,
    initialValue: T | undefined
): [T | undefined, (value: T | undefined) => void] {
    const validUntilRef = useRef<number>(0)
    const [ephemeralValue, setEphemeralValue] = useState<T | undefined>(initialValue)

    const setValue = useCallback(
        (value: T | undefined) => {
            if (value !== undefined) {
                validUntilRef.current = new Date().getTime() + ttlMs
            }
            setEphemeralValue(value)
        },
        [ttlMs]
    )

    useEffect(() => {
        if (ephemeralValue === undefined) {
            return () => null
        }
        let _timeout: number | undefined = undefined
        const validMs = validUntilRef.current - new Date().getTime()
        if (validMs > 0) {
            _timeout = setTimeout(() => {
                setEphemeralValue(undefined)
                _timeout = undefined
            }, validMs) as unknown as number
        } else {
            setEphemeralValue(undefined)
        }
        return () => {
            if (_timeout !== undefined) {
                clearTimeout(_timeout)
                _timeout = undefined
            }
        }
    }, [ephemeralValue])

    return [ephemeralValue, setValue]
}

export function usePrevious<T>(value: T): T | undefined {
    const ref = useRef<T | undefined>(undefined)
    useEffect(() => {
        ref.current = value
    })
    return ref.current
}

export function useValueJustDefined(value: unknown): boolean {
    const prevValue = usePrevious(value)
    return prevValue === undefined && value !== undefined
}

export function useStateAndDirty(originalValue: string | (() => string)) {
    const [value, _setValue] = useState<string>(originalValue)
    const [dirty, setDirty] = useState<boolean>(false)

    useEffect(() => {
        _setValue(originalValue)
        setDirty(false)
    }, [originalValue])

    const setValue = useCallback(
        (newValue: string) => {
            _setValue(newValue)
            if (dirty) {
                if (newValue.length === originalValue.length && newValue === originalValue) {
                    setDirty(false)
                }
            } else {
                if (newValue !== originalValue) {
                    setDirty(true)
                }
            }
        },
        [dirty, originalValue]
    )

    return [value, setValue, dirty] as const
}

export function useElementSize(elementId: string): { width: number; height: number } | null {
    const [size, setSize] = useState<{ width: number; height: number } | null>(null)
    useEffect(() => {
        const element = document.getElementById(elementId)
        let resizeObserver: ResizeObserver | undefined
        if (element) {
            setSize({ width: element.clientWidth, height: element.clientHeight })
            resizeObserver = new ResizeObserver(() => {
                setSize({ width: element.clientWidth, height: element.clientHeight })
            })
            resizeObserver.observe(element)
        }
        return () => resizeObserver?.disconnect()
    }, [elementId])
    return size
}

export function useBreakpoint(breakpoint: keyof Breakpoints): boolean {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const breakPointActive = useSelector<any>(state => state.context.breakpoints[breakpoint])
    return !!breakPointActive
}

export function useIsSmallScreen(): boolean {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const mdActive = useSelector<any>(state => state.context.breakpoints['md'])
    return !mdActive
}
