import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
    Dialog,
    DialogTitle,
    Stack,
    TextField,
    DialogActions,
    DialogContent,
    Typography,
    Tooltip,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Slider,
    Checkbox,
    FormControlLabel,
} from '@mui/material'
import { Add as AddIcon, ContentCopy as CreateCopyIcon } from '@mui/icons-material'

import {
    getIdOrThrow,
    roundTo2Decimals,
    roundToFullThousands,
    truncate,
} from '@a10base/common/misc.js'
import {
    AsyncButton,
    BaseTable,
    BaseTableColumn,
    PageTitleRow,
    RemoveButton,
    SaveCancelButton,
} from '@a10base/frontend/components/index.js'
import { useAsyncFn, useAsyncFn1Param } from '@a10base/frontend/hooks/index.js'
import { trpc } from '../../../util/index.js'
import {
    createScoringFunction,
    formatLoanAmount,
    getScoreText,
    randomScoringFnInput,
    scoreOptions,
    scoringOptions,
    TestCase,
} from '@a10yll/common/index.js'
import { useItemsAdmin, useUpsertItems } from '../../../hooks/index.js'
import { jsonbToObjectX } from '@a10base/common/db.js'
import { ScoringFnInput } from '@a10yll/common/yup/scoring.js'
import { useAppDispatch } from '../../../redux/redux-hooks.js'
import { itemActions } from '@a10base/frontend/redux/item-actions.js'

export function TestCasePage() {
    const [codes, loadingCodes] = useItemsAdmin('code', '')
    const [testCases, loadingTestCases] = useItemsAdmin('test_case', '', {
        sorterFn: sortTestCases,
    })
    const [selected, setSelected] = useState<TestCase | undefined>(undefined)
    const upsert = useUpsertItems('test_case')

    const createAction = useAsyncFn(
        useCallback(async () => {
            const created = await trpc.admin.testCase.create.mutate({
                description: `Test case ${testCases.length + 1}`,
                scoringInput: randomScoringFnInput(),
                expected_score: 0,
            })
            upsert(created)
            setSelected(created)
        }, [upsert, testCases.length])
    )

    const columns = useMemo<BaseTableColumn<TestCase>[]>(() => {
        const codeColumns: BaseTableColumn<TestCase>[] = codes.map(code => {
            const scoringFn = createScoringFunction(code.code)
            return {
                id: `code_${code.id}`,
                header: <strong>{truncate(code.name, 32)}</strong>,
                render: v => {
                    const [score] = scoringFn(jsonbToObjectX(v.input))
                    const scoreDelta = Math.abs(score - v.expected_score)
                    return (
                        <Stack direction="row" alignItems="center" spacing={1}>
                            {renderScoreDelta(scoreDelta)}
                            {scoreDelta > 0 && <div>{getScoreText(score)}</div>}
                        </Stack>
                    )
                },
            }
        })
        return [
            {
                id: 'desc',
                header: 'Description',
                render: renderDescription,
            },
            {
                id: 'expected_score',
                header: 'Expected score',
                render: v => getScoreText(v.expected_score),
            },
            ...codeColumns,
            {
                id: 'revenue',
                header: 'Revenue',
                render: renderRevenue,
            },
            {
                id: 'profit',
                header: 'Profit',
                render: renderProfit,
            },
            {
                id: 'company_age',
                header: 'Company age',
                render: renderCompanyAge,
            },
            {
                id: 'loan_amount',
                header: 'Loan amount',
                render: renderLoanAmount,
            },
            {
                id: 'collateral',
                header: 'Collateral',
                render: renderCollateral,
            },
            {
                id: 'interest',
                header: 'Interest',
                render: renderInterest,
            },
            {
                id: 'payback_time',
                header: 'Payback time',
                render: renderPaybackTime,
            },
        ]
    }, [codes])

    return (
        <Stack direction="column" spacing={2}>
            <PageTitleRow title="Test cases">
                <AsyncButton
                    {...createAction}
                    variant="outlined"
                    color="primary"
                    size="small"
                    startIcon={<AddIcon />}
                    ml={2}
                >
                    New test case
                </AsyncButton>
            </PageTitleRow>
            <BaseTable
                columns={columns}
                loadingRows={loadingTestCases || loadingCodes}
                rows={testCases}
                size="small"
                uniqueRowId={getIdOrThrow}
                rowProps={v => ({ onClick: () => setSelected(v), sx: { cursor: 'pointer' } })}
            />
            {selected && (
                <TestCaseEditorModal
                    testCase={selected}
                    onClose={() => setSelected(undefined)}
                    onSelectAnother={setSelected}
                />
            )}
        </Stack>
    )
}

function sortTestCases(a: TestCase, b: TestCase) {
    return a.id < b.id ? -1 : 1
}

function renderDescription(testCase: TestCase) {
    if (testCase.description && testCase.description.length > 32) {
        return (
            <Tooltip title={testCase.description}>
                <Typography variant="body1" fontSize={12}>
                    {truncate(testCase.description, 32)}
                </Typography>
            </Tooltip>
        )
    }
    return (
        <Typography variant="body1" fontSize={12}>
            {testCase.description}
        </Typography>
    )
}

const scoreDeltaColors = ['#90ee90', '#eeed90', '#ee9090', '#ed5a5a']
function renderScoreDelta(scoreDelta: number) {
    return (
        <div
            style={{
                width: '15px',
                height: '15px',
                borderRadius: '2px',
                backgroundColor: scoreDeltaColors[scoreDelta] ?? '#ed0b0b',
            }}
        ></div>
    )
}

function renderRevenue(testCase: TestCase) {
    const input = jsonbToObjectX<ScoringFnInput>(testCase.input)
    return formatMoney(input.revenue)
}

function renderProfit(testCase: TestCase) {
    const input = jsonbToObjectX<ScoringFnInput>(testCase.input)
    return formatMoney(input.profit)
}

function formatMoney(value: number) {
    const sign = value < 0 ? '-' : ''
    const absValue = Math.abs(value)
    if (absValue >= 10 ** 9) {
        return `${sign}${roundTo2Decimals(absValue / 10 ** 9)} B`
    }
    if (absValue >= 10 ** 6) {
        return `${sign}${roundTo2Decimals(absValue / 10 ** 6)} M`
    }
    if (absValue >= 100_000) {
        return `${sign}${roundToFullThousands(absValue).toString()}`
    }
    return Math.round(value).toString()
}

function renderCompanyAge(testCase: TestCase) {
    const input = jsonbToObjectX<ScoringFnInput>(testCase.input)
    return roundTo2Decimals(input.company_age) + 'y'
}

function renderLoanAmount(testCase: TestCase) {
    const input = jsonbToObjectX<ScoringFnInput>(testCase.input)
    return formatLoanAmount(input.loan_amount)
}

function renderCollateral(testCase: TestCase) {
    const input = jsonbToObjectX<ScoringFnInput>(testCase.input)
    return input.collateral ? 'Yes' : 'No'
}

function renderInterest(testCase: TestCase) {
    const input = jsonbToObjectX<ScoringFnInput>(testCase.input)
    return input.interest + '%'
}

function renderPaybackTime(testCase: TestCase) {
    const input = jsonbToObjectX<ScoringFnInput>(testCase.input)
    return input.payback_time + ' years'
}

interface TestCaseEditorModalProps {
    testCase: TestCase
    onClose: () => void
    onSelectAnother: (testCase: TestCase) => void
}
function TestCaseEditorModal({ testCase, onClose, onSelectAnother }: TestCaseEditorModalProps) {
    const [input, setInput] = useState<ScoringFnInput>({
        ...jsonbToObjectX<ScoringFnInput>(testCase.input),
    })
    const [expectedScore, setExpectedScore] = useState<number>(0)
    const [description, setDescription] = useState<string>(testCase.description ?? '')
    useEffect(() => {
        setInput({ ...jsonbToObjectX<ScoringFnInput>(testCase.input) })
        setExpectedScore(testCase.expected_score)
        setDescription(testCase.description ?? '')
    }, [testCase])
    const upsert = useUpsertItems('test_case')
    const dispatch = useAppDispatch()
    const updateAction = useAsyncFn1Param(
        trpc.admin.testCase.update.mutate,
        { id: testCase.id, description, expected_score: expectedScore, scoringInput: input },
        updated => {
            upsert(updated)
            onClose()
        }
    )
    const createCopy = useAsyncFn1Param(
        trpc.admin.testCase.createCopy.mutate,
        { id: testCase.id },
        created => {
            upsert(created)
            onSelectAnother(created)
        }
    )
    const remove = useAsyncFn1Param(trpc.admin.testCase.remove.mutate, { id: testCase.id }, () => {
        dispatch(itemActions.deleteItem('test_case', testCase.id))
        onClose()
    })
    function updateInput(field: keyof ScoringFnInput, value: unknown) {
        setInput(v => ({ ...v, [field]: value }))
    }
    return (
        <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Test case</DialogTitle>
            <DialogContent>
                <Stack direction="column" spacing={1.5} my={1}>
                    <TextField
                        value={description}
                        autoComplete="off"
                        onChange={e => setDescription(e.target.value.substring(0, 512))}
                        label="Description"
                        sx={{ mb: 2 }}
                    />
                    <Button
                        variant="text"
                        size="small"
                        onClick={() => setInput(randomScoringFnInput())}
                        sx={{ alignSelf: 'flex-start' }}
                    >
                        random values
                    </Button>
                    <FormControl fullWidth>
                        <Typography variant="subtitle2">
                            Revenue {formatMoney(input.revenue)} €
                        </Typography>
                        <Slider
                            value={input.revenue}
                            step={10000}
                            min={0}
                            max={10_000_000}
                            onChange={(_event, value) => updateInput('revenue', value as number)}
                            valueLabelDisplay="auto"
                        />
                    </FormControl>

                    <FormControl fullWidth>
                        <Typography variant="subtitle2">
                            Profit {formatMoney(input.profit)} €
                        </Typography>
                        <Slider
                            id="profit"
                            value={input.profit}
                            step={1000}
                            min={-500_000}
                            max={500_000}
                            onChange={(_event, value) => updateInput('profit', value as number)}
                            valueLabelDisplay="auto"
                        />
                    </FormControl>

                    <FormControl fullWidth>
                        <InputLabel id="company_age">Company age</InputLabel>
                        <Select
                            labelId="company_age"
                            label="company_age"
                            value={input.company_age}
                            onChange={e => updateInput('company_age', Number(e.target.value))}
                        >
                            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(v => (
                                <MenuItem key={v} value={v}>
                                    {v} years
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth>
                        <InputLabel id="loan-amount">Loan amount</InputLabel>
                        <Select
                            labelId="loan-amount"
                            label="Loan amount"
                            value={input.loan_amount}
                            onChange={e => updateInput('loan_amount', Number(e.target.value))}
                        >
                            {scoringOptions.loanAmount.map(v => (
                                <MenuItem key={v.value} value={v.value}>
                                    {v.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={input.collateral}
                                onChange={e => updateInput('collateral', e.target.checked)}
                            />
                        }
                        label="Collateral"
                    />
                    <FormControl fullWidth>
                        <InputLabel id="interest">Interest</InputLabel>
                        <Select
                            labelId="interest"
                            label="Interest"
                            value={input.interest}
                            onChange={e => updateInput('interest', Number(e.target.value))}
                        >
                            {scoringOptions.interest.map(v => (
                                <MenuItem key={v.value} value={v.value}>
                                    {v.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id="payback-time">Payback time</InputLabel>
                        <Select
                            labelId="payback-time"
                            label="Payback time"
                            value={input.payback_time}
                            onChange={e => updateInput('payback_time', Number(e.target.value))}
                        >
                            {scoringOptions.paybackTime.map(v => (
                                <MenuItem key={v.value} value={v.value}>
                                    {v.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Typography variant="subtitle2" mt={3}>
                        Expected score
                    </Typography>
                    <FormControl fullWidth>
                        <Select
                            value={expectedScore}
                            onChange={e => setExpectedScore(Number(e.target.value))}
                        >
                            {scoreOptions.map(v => (
                                <MenuItem key={v.value} value={v.value}>
                                    {v.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
            </DialogContent>
            <DialogActions sx={{ paddingTop: 2 }}>
                <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                >
                    <Stack direction="row" spacing={1} alignItems="center">
                        <RemoveButton {...remove} />
                        <AsyncButton
                            {...createCopy}
                            variant="outlined"
                            startIcon={<CreateCopyIcon />}
                        >
                            Create copy
                        </AsyncButton>
                    </Stack>
                    <SaveCancelButton
                        {...updateAction}
                        onCancel={onClose}
                        noIcon
                        disabled={description.length < 2}
                    />
                </Stack>
            </DialogActions>
        </Dialog>
    )
}
