import * as yup from 'yup'

import { objectStrict } from '@a10base/common/yup/common.js'
import { isValidFinnishHetu } from '@a10base/common/index.js'

yup.setLocale({
    mixed: {
        required: 'Pakollinen kenttä',
    },
    string: {
        email: 'Virheellinen sähköpostiosoite',
    },
})

export const scoringFnInput = objectStrict({
    revenue: yup.number().min(0).required(),
    profit: yup.number().required(),
    company_age: yup.number().required(),
    loan_amount: yup
        .number()
        .min(0)
        .max(10 ** 9)
        .required(),
    collateral: yup.boolean().required(),
    interest: yup.number().min(0).max(100).required(),
    payback_time: yup.number().min(1).max(100).required(), // years
})
export type ScoringFnInput = yup.InferType<typeof scoringFnInput>

export const scoringRequest = objectStrict({
    business_id: yup.string().max(32).required(),
    loan_amount: yup
        .number()
        .min(0)
        .max(10 ** 9)
        .required(),
    collateral: yup.boolean().required(),
    interest: yup.number().min(0).max(100).required(),
    payback_time: yup.number().min(1).max(100).required(), // years
}).defined()
export type ScoringRequest = yup.Asserts<typeof scoringRequest>

export const contactForm = objectStrict({
    // business_id: yup
    //     .string()
    //     .trim()
    //     .test(
    //         'y-tunnus',
    //         'Virheellinen y-tunnus',
    //         value => value !== undefined && isValidFinnishVatId(value)
    //     )
    //     .required(),
    // business_id: yup.string().max(32).required(),
    email_address: yup.string().max(128).email().required(),
    mobile_phone: yup
        .string()
        .trim()
        .matches(/^(\+)[1-9][0-9]{7,15}$/, 'Virheellinen puhelinnumero')
        .required(),
    personal_id: yup
        .string()
        .trim()
        .test(
            'hetu',
            'Virheellinen henkilötunnus',
            value => value !== undefined && isValidFinnishHetu(value)
        )
        .required(),
    first_name: yup.string().trim().min(1).max(64).required(),
    last_name: yup.string().trim().min(1).max(64).required(),
}).defined()
export type ContactForm = yup.Asserts<typeof contactForm>

export const contactFormPart1 = objectStrict({
    email_address: yup.string().max(128).email().required(),
    mobile_phone: yup
        .string()
        .trim()
        .matches(/^(\+)[1-9][0-9]{7,15}$/, 'Virheellinen puhelinnumero')
        .required(),
}).defined()
export type ContactFormPart1 = yup.Asserts<typeof contactFormPart1>

export const contactFormPart2 = objectStrict({
    personal_id: yup
        .string()
        .trim()
        .test(
            'hetu',
            'Virheellinen henkilötunnus',
            value => value !== undefined && isValidFinnishHetu(value)
        )
        .required(),
    first_name: yup.string().trim().min(1).max(64).required(),
    last_name: yup.string().trim().min(1).max(64).required(),
}).defined()
export type ContactFormPart2 = yup.Asserts<typeof contactFormPart2>

export const sendApplication = objectStrict({
    scoringId: yup.number().integer().required(),
    contactForm: contactForm.required(),
}).defined()
