import type { LoanChance } from '../../types/scoring.js'
import { ScoringFnInput } from '../../yup/index.js'
import { scoringOptions } from './options.js'
import _ from 'lodash'

export const sampleScoringFnInput: ScoringFnInput = {
    //credit_score: scoringOptions.creditScore[0].value as CreditScore,
    // revenue: scoringOptions.revenue[0].value,
    // company_age: scoringOptions.companyAge[0].value,
    revenue: 100000.6,
    company_age: 2.5,
    profit: 10000.5,
    loan_amount: scoringOptions.loanAmount[0].value,
    collateral: false,
    interest: scoringOptions.interest[0].value,
    payback_time: scoringOptions.paybackTime[0].value,
}

export function randomScoringFnInput(): ScoringFnInput {
    const bigCompany = Math.random() < 0.01
    const revenue = bigCompany ? _.random(1_000_000, 10_000_000) : _.random(0, 1_000_000)
    const maxProfit = Math.min(revenue / 2, 500_000)
    const profit = _.random(-maxProfit, maxProfit)
    return {
        revenue,
        company_age: _.random(0, 15),
        profit,
        loan_amount: _.shuffle(scoringOptions.loanAmount)[0].value,
        collateral: Math.random() < 0.5,
        interest: _.shuffle(scoringOptions.interest)[0].value,
        payback_time: _.shuffle(scoringOptions.paybackTime)[0].value,
    }
}

export type ScoringFunction = (input: ScoringFnInput) => [LoanChance, string[]]

export function createScoringFunction(code: string): ScoringFunction {
    // eslint-disable-next-line @typescript-eslint/no-implied-eval
    const scoringFn = new Function(
        'input',
        `
        ${code}
        let [chances, steps] = calculateChances(input)
        chances = Math.round(chances)
        chances = Math.min(4, Math.max(0, chances))
        return [chances, steps]
    `
    )
    return scoringFn as ScoringFunction
}

export function validateScoringFunction(scoringFn: ScoringFunction): void {
    // This will throw if the function is invalid
    scoringFn(sampleScoringFnInput)
}

// export function calculateScore(code: string, input: ScoringFnInput): [number, string[]] {
//     const fn = createScoringFunction(code)
//     return fn(input) // 0 = no chance, 1 = small chance, 2 = reasonable chance, 3 = good chance, 4 = very good chance
// }
