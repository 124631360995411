/* eslint-disable @typescript-eslint/no-explicit-any */
import { ThunkAction, Action } from '@reduxjs/toolkit'

import type * as schema from 'zapatos/schema'
import {
    deleteItemRaw,
    deleteItemsRaw,
    itemSlice,
    upsertItemRaw,
    upsertItemsRaw,
} from './reducers/item-slice.js'

type AppThunk = ThunkAction<void, any, any, Action<string>>

export function upsertItemsBase<T extends schema.TableWithIdAndVersion>(
    table: T,
    items: schema.JSONSelectableForTable<T>[]
): AppThunk {
    return upsertItemsRaw(table, items)
}

export function upsertItemBase<T extends schema.TableWithIdAndVersion>(
    table: T,
    item: schema.JSONSelectableForTable<T>
): AppThunk {
    return upsertItemRaw(table, item)
}

export const itemActions = {
    clearTable: itemSlice.actions.clearTable,
    clearAllTables: itemSlice.actions.clearAllTables,
    upsertItem: upsertItemBase,
    upsertItems: upsertItemsBase,
    deleteItem: deleteItemRaw,
    deleteItems: deleteItemsRaw,
}
