import React, { useState } from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    TextField,
    Typography,
} from '@mui/material'
import { Done as DoneIcon } from '@mui/icons-material'

import { trpc } from '../../../../util/trpc.js'
import {
    useAsyncFn1Param,
    useFormData,
    useStateLS,
    useValidatedAsyncFn,
} from '@a10base/frontend/hooks/index.js'
import { CancelButton, FormInputError, SaveButton } from '@a10base/frontend/components/index.js'
import { yup } from '@a10yll/common/index.js'
import { serverData } from '@a10base/frontend/util/server-data.js'
import { randomString } from '@a10base/common/misc.js'
import { ContactFormPart1 } from '@a10yll/common/yup/scoring.js'

const isProd = serverData.config.A10_ENV === 'prod'

const testContactPart1: yup.ContactFormPart1 = {
    email_address: 'support@eipena.com',
    mobile_phone: '+35840' + randomString(7, '0123456789'),
}
const testContactPart2: yup.ContactFormPart2 = {
    personal_id: '010169-1237',
    first_name: 'Pena',
    last_name: 'Penanen',
}
const emptyContactPart1: yup.ContactFormPart1 = {
    email_address: '',
    mobile_phone: '+358',
}
const emptyContactPart2: yup.ContactFormPart2 = {
    personal_id: '',
    first_name: '',
    last_name: '',
}
interface ContactFormModalProps {
    businessId: string
    scoringId: number
    onClose: () => void
}
export function ContactFormModal({ scoringId, businessId, onClose }: ContactFormModalProps) {
    const [state, setState] = useState<'company-info' | 'concent-details' | 'person-info' | 'sent'>(
        'company-info'
    )
    const [companyInfo, setCompanyInfo] = useStateLS<ContactFormPart1>(
        'company-info',
        isProd ? emptyContactPart1 : testContactPart1
    )

    switch (state) {
        case 'company-info':
            return (
                <CompanyInfoModal
                    businessId={businessId}
                    formData={companyInfo}
                    onClose={onClose}
                    onContinue={value => {
                        setCompanyInfo(value)
                        setState('person-info')
                    }}
                    onShowDetails={() => setState('concent-details')}
                />
            )
        case 'concent-details':
            return <DetailsModal onOk={() => setState('company-info')} onClose={onClose} />
        case 'person-info':
            return (
                <PersonInfoModal
                    companyInfo={companyInfo}
                    scoringId={scoringId}
                    onSent={() => setState('sent')}
                    onClose={onClose}
                />
            )
        case 'sent':
            return <ThankYouModal onClose={onClose} />
    }
}

interface CompanyInfoModalProps {
    businessId: string
    formData: ContactFormPart1
    onClose: () => void
    onContinue: (contactFormPart1: yup.ContactFormPart1) => void
    onShowDetails: () => void
}
function CompanyInfoModal({
    businessId,
    formData,
    onClose,
    onContinue,
    onShowDetails,
}: CompanyInfoModalProps) {
    const { values, setValue, errors, validate } = useFormData(
        yup.contactFormPart1,
        formData
        // isProd ? emptyContactPart1 : testContactPart1
    )
    const handleContinue = () => {
        const validationErrors = validate()
        if (validationErrors === null) {
            onContinue(values)
        }
    }
    return (
        <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth transitionDuration={0}>
            <DialogTitle pb={2}>Lähetä hakemus</DialogTitle>
            <DialogContent>
                <Stack direction="column" spacing={3} mt={2}>
                    <Typography variant="h6">Yrityksen tiedot</Typography>
                    <TextField
                        label="Y-tunnus"
                        name="y-tunnus"
                        placeholder="1234567-8"
                        value={businessId}
                        disabled
                        fullWidth
                    />

                    <TextField
                        label="Yrityksen sähköposti"
                        name="email"
                        value={values.email_address}
                        onChange={e => setValue('email_address', e.target.value)}
                        onBlur={e => setValue('email_address', e.target.value)}
                        fullWidth
                    />
                    <FormInputError errorMsg={errors?.get('email_address')} />

                    <TextField
                        label="Puhelinnumero"
                        name="phone"
                        placeholder="+358 40 123 4567"
                        value={values.mobile_phone}
                        onChange={e => setValue('mobile_phone', e.target.value)}
                        onBlur={e => setValue('mobile_phone', normalisePhone(e.target.value))}
                        fullWidth
                    />
                    <FormInputError errorMsg={errors?.get('mobile_phone')} />

                    <Typography variant="body1">
                        Lähettämällä hakemuksen vahvistat, että olet lukenut ja hyväksynyt{' '}
                        <a href="https://www.capitalbox.fi/terms-use" target="_blank">
                            tietosuojakäytäntömme
                        </a>
                        , sekä annat CapitalBoxille suostumuksen tietojen noutamiseen ja käsittelyyn{' '}
                        <a
                            href="#"
                            onClick={event => {
                                event.preventDefault()
                                event.stopPropagation()
                                onShowDetails()
                            }}
                        >
                            Yritysluottojen kyselyjärjestelmästä
                        </a>
                        .
                    </Typography>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack direction="row" alignItems="center" spacing={3} mt={2}>
                    {!!errors?.size && (
                        <Typography variant="body2" color="error">
                            Tarkista tiedot
                        </Typography>
                    )}
                    <CancelButton onCancel={onClose} text="Peruuta" />
                    <Button onClick={handleContinue} variant="contained" color="primary">
                        Jatka
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    )
}

interface DetailsModalProps {
    onOk: () => void
    onClose: () => void
}
function DetailsModal({ onOk, onClose }: DetailsModalProps) {
    return (
        <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth transitionDuration={0}>
            <DialogTitle pb={2}>Yritysluottojen kyselyjärjestelmä</DialogTitle>
            <DialogContent>
                <Stack direction="column" spacing={3} mt={2}>
                    <Typography variant="body1">
                        Annan suostumuksen luotonantajalle kerätä ja käsitellä muilta
                        luotonantajilta saatavia tietoja voimassa olevista ja viimeisen 12 kuukauden
                        aikana erääntyneistä luotoistamme ja muista sitoumuksistamme sisältäen
                        tiedot, jotka olen antanut näille luotonantajille käytettäväksi
                        luottohakemuksen käsittelyä varten. Annan suostumuksen sille, että
                        luotonantajat luovuttavat näitä yrityksen luottoja koskevia tietoja toisille
                        luotonantajille. Näitä tietoja luotonantaja kysyy Suomen Asiakastieto Oy:n
                        ylläpitämän teknisen kyselyjärjestelmän (Yritysluottojen kyselyjärjestelmä,
                        myös: BLIS) avulla järjestelmään osallistuvilta yhtiöiltä.
                    </Typography>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={onOk} color="primary" variant="contained" sx={{ mt: 2 }}>
                    Selvä
                </Button>
            </DialogActions>
        </Dialog>
    )
}

interface PersonInfoModalProps {
    scoringId: number
    companyInfo: ContactFormPart1
    onClose: () => void
    onSent: () => void
}
function PersonInfoModal({ scoringId, companyInfo, onClose, onSent }: PersonInfoModalProps) {
    const { values, errors, validate, setValue } = useFormData(
        yup.contactFormPart2,
        isProd ? emptyContactPart2 : testContactPart2
    )
    const sendApplication = useAsyncFn1Param(
        trpc.public.sendApplication.mutate,
        {
            scoringId,
            contactForm: { ...companyInfo, ...values },
        },
        onSent
    )
    const validateAndSend = useValidatedAsyncFn(sendApplication, validate)

    return (
        <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth transitionDuration={0}>
            <DialogTitle pb={2}>Lähetä hakemus</DialogTitle>
            <DialogContent>
                <Stack direction="column" spacing={3} mt={2}>
                    <Typography variant="h6">Yhteyshenkilön tiedot</Typography>

                    <TextField
                        label="Etunimi"
                        name="first_name"
                        value={values.first_name}
                        onChange={e => setValue('first_name', e.target.value)}
                        fullWidth
                    />
                    <FormInputError errorMsg={errors?.get('first_name')} />

                    <TextField
                        label="Sukunimi"
                        name="last_name"
                        value={values.last_name}
                        onChange={e => setValue('last_name', e.target.value)}
                        fullWidth
                    />
                    <FormInputError errorMsg={errors?.get('last_name')} />

                    <TextField
                        label="Henkilötunnus"
                        placeholder="241270-123A"
                        autoComplete="off"
                        value={values.personal_id}
                        onChange={e => setValue('personal_id', e.target.value)}
                        onBlur={e => setValue('personal_id', e.target.value)}
                        fullWidth
                    />
                    <FormInputError errorMsg={errors?.get('personal_id')} />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack direction="row" alignItems="center" spacing={3} mt={2}>
                    {!!errors?.size && (
                        <Typography variant="body2" color="error">
                            Tarkista tiedot
                        </Typography>
                    )}
                    <CancelButton onCancel={onClose} text="Peruuta" />
                    <SaveButton
                        {...validateAndSend}
                        size="medium"
                        text="Lähetä lainahakemus"
                        noIcon
                    />
                </Stack>
            </DialogActions>
        </Dialog>
    )
}

interface ThankYouModalContentProps {
    onClose: () => void
}
function ThankYouModal({ onClose }: ThankYouModalContentProps) {
    return (
        <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogContent>
                <Stack direction="column" spacing={2} p={2}>
                    <Typography
                        variant="h1"
                        fontSize="32px"
                        sx={{ display: 'flex', alignItems: 'center' }}
                    >
                        <DoneIcon
                            sx={{
                                backgroundColor: '#1aa81a',
                                color: 'white',
                                borderRadius: '16px',
                                fontSize: '32px',
                                marginRight: '10px',
                            }}
                        />
                        Kiitos hakemuksesta!
                    </Typography>
                    <p>Olemme sinuun yhteydessä mahdollisimman pian.</p>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary" variant="contained" sx={{ mt: 2 }}>
                    Sulje
                </Button>
            </DialogActions>
        </Dialog>
    )
}

function normalisePhone(phoneNumber: string): string {
    let p = phoneNumber.replace(/\s/g, '')
    if (p.length === 0) {
        return p
    }
    if (!p.startsWith('+')) {
        p = '+358' + p
    }
    if (p.startsWith('+3580')) {
        p = p.replace('+3580', '+358')
    }
    return p
}
