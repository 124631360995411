import { nullToUndefined, parseNumber } from '@a10base/common/misc.js'
import { logger } from './client-logger.js'
import { hist } from './history.js'

export interface SearchParams {
    first: { [name: string]: string | undefined }
    array: { [name: string]: string[] }
}

export function parseSearchParams(search: string): SearchParams {
    const params = new URLSearchParams(search)
    const searchParams: SearchParams = {
        first: {},
        array: {},
    }
    for (const key of params.keys()) {
        searchParams.first[key] = nullToUndefined(params.get(key))
        searchParams.array[key] = params.getAll(key)
    }
    return searchParams
}

export function getUrlQueryValue(paramName: string | undefined): string | undefined {
    if (paramName === undefined) {
        return undefined
    }
    const params = new URLSearchParams(hist.location.search)
    return params.get(paramName) || undefined
}
export function getUrlQueryNumber(paramName: string | undefined): number | undefined {
    const value = getUrlQueryValue(paramName)
    return parseNumber(value)
}

// Returns updated url query in a string form (e.g. 'foo=42&bar=baz').
// Note: url params that are not defined in 'updatedParams' are not touched
function getUpdatedUrlQueryString(
    updatedParams: Record<string, string | string[] | undefined>
): string {
    const params = new URLSearchParams(hist.location.search)
    for (const paramName in updatedParams) {
        const value = updatedParams[paramName]
        if (value === undefined) {
            params.delete(paramName)
        } else {
            if (Array.isArray(value)) {
                params.delete(paramName)
                value.forEach(item => {
                    params.append(paramName, item)
                })
            } else {
                params.set(paramName, value)
            }
        }
    }
    return params.toString()
}

interface UpdateUrlQueryOptions {
    replace?: boolean
}
export function updateUrlQuery(
    params: Record<string, string | string[] | undefined>,
    options?: UpdateUrlQueryOptions
): void {
    const newSearch = getUpdatedUrlQueryString(params)
    const oldSearch = hist.location.search.replace('?', '')
    if (newSearch !== oldSearch) {
        logger.debug('Updating url query', {
            params,
            options,
            newSearch,
            oldSearch,
        })
        if (options?.replace) {
            hist.replace({ search: newSearch })
        } else {
            hist.push({ search: newSearch })
        }
    }
}

export function urlQueryUpdater(paramName: string): (value: string | string[] | undefined) => void {
    return value => updateUrlQuery({ [paramName]: value })
}
