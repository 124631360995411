import { useEffect, useRef, useState, useCallback, useMemo } from 'react'
import _ from 'lodash'

import { nop } from '@a10base/common/misc.js'
import { logger } from '../util/index.js'

export function useThrottled1<P>(
    fn: (p: P) => void,
    waitMs: number,
    options?: { leading?: boolean; trailing?: boolean }
): _.DebouncedFunc<(p: P) => void> {
    const fnRef = useRef<(p: P) => void>(fn)
    fnRef.current = fn
    //const handle = useRef<_.DebouncedFunc<(p: P) => void>>()
    // useEffect(() => {
    //     return () => handle.current?.cancel()
    // }, [fn])
    return useMemo(() => {
        //handle.current?.cancel()
        return _.throttle(fnRef.current, waitMs, {
            leading: options?.leading,
            trailing: options?.trailing,
        })
        // return handle.current
    }, [waitMs, options?.leading, options?.trailing])
}

export function useThrottled2<P1, P2>(
    fn: (p1: P1, p2: P2) => void,
    waitMs: number,
    options?: { leading?: boolean; trailing?: boolean }
): _.DebouncedFunc<(p1: P1, p2: P2) => void> {
    const fnRef = useRef<(p1: P1, p2: P2) => void>(fn)
    fnRef.current = fn
    return useMemo(() => {
        return _.throttle(fnRef.current, waitMs, {
            leading: options?.leading,
            trailing: options?.trailing,
        })
    }, [waitMs, options?.leading, options?.trailing])
}

export function useThrottled3<P1, P2, P3>(
    fn: (p1: P1, p2: P2, p3: P3) => void,
    waitMs: number,
    options?: { leading?: boolean; trailing?: boolean }
): _.DebouncedFunc<(p1: P1, p2: P2, p3: P3) => void> {
    const fnRef = useRef<(p1: P1, p2: P2, p3: P3) => void>(fn)
    fnRef.current = fn
    return useMemo(() => {
        return _.throttle(fnRef.current, waitMs, {
            leading: options?.leading,
            trailing: options?.trailing,
        })
    }, [waitMs, options?.leading, options?.trailing])
}

export function useStateThrottled<T>(
    initialState: T | (() => T),
    throttleMs: number,
    options?: _.ThrottleSettings
): [T, (value: T) => void] {
    const [value, setValue] = useState<T>(initialState)
    const setValueThrottled = useThrottled1(setValue, throttleMs, options ?? { trailing: true })
    return [value, setValueThrottled]
}

interface UseThrottledAsyncFnResponse<P> {
    setParams: (params: P) => void
    //params: P | undefined
    requesting: boolean
    throttling: boolean
}
export function useThrottledAsyncFn<P>(
    asyncFn: (params: P) => Promise<void>,
    throttleMs: number
): UseThrottledAsyncFnResponse<P> {
    const refs = useRef({
        params: undefined as P | undefined,
        paramsInFlight: undefined as P | undefined,
        throttling: undefined as ReturnType<typeof setTimeout> | undefined,
        asyncFn: asyncFn,
    })
    refs.current.asyncFn = asyncFn
    const [requesting, setRequesting] = useState<boolean>(false)
    //const [throttling, setThrottling] = useState<boolean>(false)
    const [trigger, setTrigger] = useState<number>(0)

    useEffect(() => {
        if (trigger === 0) {
            return
        }
        if (refs.current.params === undefined) {
            logger.debug('useThrottledAsyncFn: No params to process')
            return
        }
        if (refs.current.paramsInFlight !== undefined) {
            logger.debug('useThrottledAsyncFn: Request already in flight', {
                paramsInFlight: refs.current.paramsInFlight,
            })
            return
        }
        if (refs.current.throttling !== undefined) {
            logger.debug('useThrottledAsyncFn: Resetting throttle timer', {
                throttleMs,
                params: refs.current.params,
            })
            clearTimeout(refs.current.throttling)
            refs.current.throttling = setTimeout(() => {
                refs.current.throttling = undefined
                setTrigger(v => v + 1)
            }, throttleMs)
            return
        }

        // At this point there are params ready to be processed, no request in flight, and no throttling
        refs.current.paramsInFlight = _.isObject(refs.current.params)
            ? { ...refs.current.params }
            : refs.current.params

        logger.debug('useThrottledAsyncFn: Requesting', {
            params: refs.current.params,
        })

        setRequesting(true)
        refs.current
            .asyncFn(refs.current.params)
            .catch(nop)
            .finally(() => {
                const newParamsWaiting =
                    refs.current.params !== undefined &&
                    !_.isEqual(refs.current.params, refs.current.paramsInFlight)
                logger.debug('useThrottledAsyncFn: Request finished', {
                    newParamsWaiting,
                    params: refs.current.params,
                    paramsInFlight: refs.current.paramsInFlight,
                })
                refs.current.paramsInFlight = undefined
                if (!newParamsWaiting) {
                    refs.current.params = undefined
                    setRequesting(false)
                }
                if (refs.current.throttling !== undefined) {
                    clearTimeout(refs.current.throttling)
                }
                logger.debug('useThrottledAsyncFn: Setting throttle timer', {
                    throttleMs,
                    params: refs.current.params,
                })
                refs.current.throttling = setTimeout(() => {
                    refs.current.throttling = undefined
                    setTrigger(v => v + 1)
                }, throttleMs)
            })
    }, [trigger, throttleMs])

    const setParams = useCallback((fnParams: P) => {
        refs.current.params = fnParams
        setTrigger(v => v + 1)
    }, [])

    return {
        setParams,
        // params: refs.current.params,
        requesting,
        throttling: refs.current.throttling !== undefined,
    }
}
