import * as yup from 'yup'

import { objectStrict, id } from '@a10base/common/yup/index.js'
import { scoringFnInput } from './scoring.js'

export const createCode = objectStrict({
    name: yup.string().trim().max(128).required(),
    code: yup.string().trim().max(50000).required(),
}).defined()
export type CreateCode = yup.InferType<typeof createCode>

export const updateCode = objectStrict({
    id: id.required(),
    name: yup.string().trim().max(128),
    code: yup.string().trim().max(50000),
}).defined()
export type UpdateCode = yup.InferType<typeof updateCode>

export const createTestCase = objectStrict({
    scoringInput: scoringFnInput.required(),
    expected_score: yup.number().oneOf([0, 1, 2, 3, 4]).required(),
    description: yup.string().trim().max(1000).defined(),
}).defined()
export type CreateTestCase = yup.InferType<typeof createTestCase>

export const updateTestCase = objectStrict({
    id: id.required(),
    scoringInput: scoringFnInput.required(),
    expected_score: yup.number().oneOf([0, 1, 2, 3, 4]).required(),
    description: yup.string().trim().max(1000).defined(),
}).defined()
export type UpdateTestCase = yup.InferType<typeof updateTestCase>
